import '../App.css';

function ErrorPage(){
    return(
    <div id="error-page">
        <div className="ErrorPage">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>Page not found</i>
            </p>
        </div>
    </div>
    )
}

export default ErrorPage;