import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';

import LandingPage from './pages/LandingPage';
import ErrorPage from './pages/ErrorPage';
import RequestPage from './pages/RequestPage';
import NavBar from './components/NavBar/NavBar';

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        
        <Routes>
          <Route path="/" element={<LandingPage/>} />
          <Route path="/request" element={<RequestPage />} />
          <Route path="*" element={<ErrorPage/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
