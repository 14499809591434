import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from 'react-router-dom';

import "./NavBar.scss";

function NavBar() {

    return (
    <AppBar position="static" style={{ background: '#a88932' }}>
        <Toolbar className="toolbar">  
            <Link to="/">
                <Typography 
                    variant="h4" 
                    component="div" >
                    Jensen Woodshop
                </Typography>
            </Link>
          <div className="buttons">
            <Link to="/request">
                <Button color="inherit">Request Work</Button>
            </Link>
          </div> 
        </Toolbar>
      </AppBar>
    );
  }
  
  export default NavBar;